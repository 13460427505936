import { link, tokenLink, dnsPortal, urlADM } from "../../services/variables";

//alguns link possuem token diferente do padrão
const linksNavbar = [
  // Município
  {
    name: `Município`,
    top_list: true,
    img: "Municipio",
    sub_link: [
      {
        name: `A Cidade`,
        link: `/municipio/cidade`,
      },
      {
        name: `Distâncias`,
        link: `/municipio/distancias`,
      },
      {
        name: `História`,
        link: `/municipio/historia`,
      },
      {
        name: `População`,
        link: `/municipio/populacao`,
      },
      {
        name: `Mapa`,
        link: `/municipio/mapa`,
      },
      // {
      //   name:`Símbolos`,
      //   link:`/municipio/simbolos`
      // },
      {
        name: `Endereços Úteis`,
        link: `/municipio/enderecos-uteis`,
      },
      // {
      //   name:`Distritos`,
      //   link:`/municipio/distritos`
      // },
      // {
      //   name:`Eventos`,
      //   link:`/municipio/eventos`
      // },
      // {
      //   name: `Obras`,
      //   link: `${link}/portaltm/licitacao/obrapublica.xhtml?token=${tokenLink}`,
      // },
      // {
      //   name:`Veículos`,
      //   link:`/municipio/veiculos`
      // },
    ],
  },
  {
    name: `Informações Institucionais`,
    top_list: true,
    img: "Institucional",
    sub_link: [
      {
        name: `Registro das Competências`,
        link: `/informacoes-institucionais/registro-das-competencias`,
      },
      // {
      //   name: `Mural`,
      //   link: `/informacoes-institucionais/mural`,
      // },
      {
        name: `Estrutura Organizacional`,
        // link: `${link}/portal/organograma/organograma.xhtml?token=${tokenLink}`,
        link: `/estrutura-organizacional`,
      },
      {
        name: `Leis Municipais / Estaduais`,
        sub_link: [
          {
            name: `Lei Orgânica`,
            //versão interna
            // link: `/legislativo-municipal/legislacao/lei-organica`,
            link: `${urlADM}/admpublica/lei-organica?token=${tokenLink}`,
          },
          {
            name: `Código Tributário`,
            link: `${urlADM}/admpublica/codigo-tributario?token=${tokenLink}`,
            // link: `/informacoes-institucionais/leis-municipais/estaduais/codigo-tributario`,
          },
          {
            name: `Leis Municipais`,
            // link: `/informacoes-institucionais/leis-municipais/estaduais/lei`,
            link: `${urlADM}/admpublica/lei?token=${tokenLink}`,
          },
          {
            name: `Leis Estaduais`,
            link: `${link}/portal/transparencia/transparencia.xhtml?token=${tokenLink}`,
          },
          {
            name: `Projeto de Leis`,
            link: `/informacoes-institucionais/leis-municipais/estaduais/projeto-de-lei`,
          },
          {
            name: `Decretos `,
            // link: `/informacoes-institucionais/leis-municipais/estaduais/decreto`,
            link: `${urlADM}/admpublica/decreto?token=${tokenLink}`,
          },
          {
            name: `Portarias`,
            //versão conjunta
            link: `${urlADM}/admpublica/portarias?token=${tokenLink}`,
          },
          {
            name: `Outros Atos Administrativos `,
            // link: `/informacoes-institucionais/leis-municipais/estaduais/outros-atos-administrativos`,
            link: `${urlADM}/admpublica/outros-atos-administrativos?token=${tokenLink}`,
          },
        ],
      },
      {
        name: "Concursos / Seletivos",
        link: `${urlADM}/licitacoes-e-contratos/concurso?token=${tokenLink}`,
      },
      {
        name: "Conselhos Municipais",
        link: `${urlADM}/admpublica/conselhos-municipais?token=${tokenLink}`,
      },
      {
        name: `Perguntas e Respostas`,
        link: `${link}/esic/perguntaresposta.xhtml?token=${tokenLink}&tipo=Perguntas%20e%20Respostas%20e-Sic`,
      },
      {
        name: `Fale Conosco`,
        link: `${urlADM}/ouvidoria?token=${tokenLink}`,
      },
    ],
  },
  // Diário Oficial
  {
    name: `Diário Oficial`,
    top_list: true,
    img: "Diario_OFI",
    sub_link: [
      {
        name: `Diário Oficial Eletrônico`,
        link: `${link}/diario/diariov2.xhtml?token=${tokenLink}`,
      },
      {
        name: `Lei de criação do Diário`,
        link: `${urlADM}/admpublica/lei-de-criacao-do-diario-oficial-eletronico-do-municipio?token=${tokenLink}`,
      },
      {
        name: `Ato de nomeação do responsável`,

        link: `${urlADM}/admpublica/ato-de-nomeacao-de-pessoa-responsavel-pelas-publicacoes-do-diario-oficial-do-municipio?token=${tokenLink}`,
      },
    ],
  },
  // Pessoal
  {
    name: `Pessoal`,
    top_list: true,
    img: "Pessoal",
    sub_link: [
      // {
      //   name: `Contracheque`,
      //   link: `#`,
      // },
      {
        name: `Estatuto do Servidor`,
        link: `${urlADM}/admpublica/estatuto-dos-servidores?token=${tokenLink}`,
      },
      {
        name: `Folha de pagamento`,
        link: `http://www.transparenciadministrativa.com.br/inforfolha/contracheque/remuneracao.xhtml?token=d0ee6b0c-8da6-4f5b-a94a-f50f11af86a6`,
        // sub_link: [
        //   {
        //     name: `Remuneração dos Servidores`,
        //     link: `#`,
        //   },
        //   {
        //     name: `Gestões Anteriores`,
        //     link: `#`,
        //   },
        // ],
      },
      {
        name: `Relação Nominal de cada servidor`,
        // link: `/pessoal/remuneracao-nominal-de-cada-servidor`,
        link: `${urlADM}/admpublica/relacao-nominal-de-cada-servidor?token=${tokenLink}`,
      },
      {
        name: `Servidores inativos`,
        // link: `/pessoal/relacao-de-servidores-inativos`,
        link: `${urlADM}/admpublica/relacao-de-servidores-inativos?token=${tokenLink}`,
      },
      {
        name: `Servidores ocupante de cargo em comissão`,
        // link: `/pessoal/relacao-de-servidores-ocupantes-de-cargo-em-comissao`,
        link: `${urlADM}/admpublica/relacao-de-servidores-ocupantes-de-cargo-em-comissao?token=${tokenLink}`,
      },
      {
        name: `Servidores cedidos para outros Órgãos Públicos`,
        // link: `/pessoal/relacao-de-servidores-cedidos-para-outros-orgaos-publicos`,
        link: `${urlADM}/admpublica/relacao-de-servidores-cedidos-para-outros-orgaos-publicos?token=${tokenLink}`,
      },
      {
        name: `Relação nominal dos estagiários`,
        // link: `/pessoal/relacao-nominal-dos-estagiarios`,
        link: `${urlADM}/admpublica/relacao-nominal-dos-estagiarios?token=${tokenLink}`,
      },
      {
        name: `Relação de contratados temporariamente e contratos de terceirização`,
        link: `${urlADM}/admpublica/relacao-nominal-dos-agentes-publicos-contratados-temporariamente-e-contratados-de-terceirizacao?token=${tokenLink}`,
      },
      {
        name: `Tabela com o padrão remuneratório dos cargos e função`,

        link: `${urlADM}/admpublica/tabela-remuneratoria-dos-cargos-e-funcoes?token=${tokenLink}`,
      },
    ],
  },
  // Diárias
  {
    name: `Diárias`,
    top_list: true,
    img: "Diárias",
    sub_link: [
      {
        name: `Tabela de valores das diárias`,
        link: `${urlADM}/admpublica/relacao-das-diarias-dentro-e-fora-estado-e-fora-do-pais?token=${tokenLink}`,
      },
      {
        name: `Diárias e Passagens`,
        link: `http://143.137.254.24:5656/scpiweb_pmsaoroberto/Default.aspx?AcessoIndividual=lnkDiarias`,
        // sub_link: [
        //   {
        //     name: `Gestão 2023/2024`,
        //     link: `http://143.137.254.24:5656/SCPIWEB_CMALTOPARNAIBA/Default.aspx?AcessoIndividual=lnkDiarias`,
        //   },
        //   {
        //     name: `Anos Anteriores`,
        //     link: `https://www.governotransparente.com.br/20809590`,
        //   },
        // ],
      },
      // {
      //   name: "Diárias - Exercício Financeiro 2020",
      //   link: `#`,
      // },
      {
        name: `Legislação`,
        // link: `/diarias/tabela-de-valores-das-diarias`,
        link: `${urlADM}/admpublica/diarias-e-legislacao?token=${tokenLink}`,
      },
    ],
  },
  // Transparência
  {
    name: `Transparência`,
    link: `/transparencia`,
    top_list: true,
    img: "Transparência",
  },
  // Licitações e Contratos
  {
    name: `Licitações e Contratos`,
    top_list: true,
    img: "licitaçoes",
    sub_link: [
      {
        name: `Plano Anual de Contratações`,
        link: `${urlADM}/admpublica/plano-anual-de-contratacoes?token=${tokenLink}`,
      },
      {
        name: `Indicação do Fiscal de Contrato`,
        link: `/licitacoes-e-contratos/indicacao-do-fiscal-do-contrato`,
      },
      {
        name: `Avisos`,
        link: `/licitacoes-e-contratos/avisos`,
      },
      {
        name: `Edital`,
        link: `/licitacoes-e-contratos/edital`,
      },
      {
        name: `Dispensas`,
        link: `/licitacoes-e-contratos/dispensas`,
      },
      {
        name: `Inexigibilidade`,
        link: `/licitacoes-e-contratos/inexigibilidade`,
      },
      {
        name: `Pregão Eletrônico`,
        link: `/licitacoes-e-contratos/pregao-eletronico`,
      },
      {
        name: `Adesão de Ata - SRP `,
        link: `/licitacoes-e-contratos/ata-de-adesao`,
      },
      {
        name: `Resultados do Certame`,
        link: `/licitacoes-e-contratos/resultado-do-certame`,
      },
      {
        name: `Desertas e Fracassadas`,
        link: `/licitacoes-e-contratos/desertas-fracassadas`,
      },
      {
        name: `Obras Públicas`,
        link: `/licitacoes-e-contratos/obras-publicas`,
      },
      {
        name: `Termo de Aditivo`,
        link: `/licitacoes-e-contratos/termo-de-aditivo`,
      },
      {
        name: `Convênio`,
        link: `/licitacoes-e-contratos/convenio`,
      },
      {
        name: `Contratos `,
        link: `/licitacoes-e-contratos/contrato`,
      },
      {
        name: `Fornecedores Sancionados`,
        link: `${urlADM}/licitacoes-e-contratos/fornecedores-sancionados?token=${tokenLink}`,
      },
    ],
  },
  // Ouvidoria
  {
    name: `Ouvidoria`,
    top_list: true,
    img: "Ouvidoria",
    sub_link: [
      {
        name: `Ato de Nomeação - Ouvidor`,
        link: `${urlADM}/admpublica/divisao/regulamentacao-da-ouvidoria?token=${tokenLink}`,
      },
      {
        name: `Atendimento Presencial`,
        link: `/ouvidoria/atendimento-presencial`,
      },
      {
        name: `Redes Sociais`,
        link: `/redes-sociais`,
      },
      {
        name: `Carta de Serviços ao Usuário`,
        link: `${urlADM}/downloads?n=01612348000100&a=carta_de_servicos.pdf`,
      },
      {
        name: `Canal Eletrônico de Acesso`,
        // link: `/ouvidoria/canal-eletronico-de-acesso`,
        link: `${urlADM}/ouvidoria?token=${tokenLink}`,
      },
      {
        name: `Perguntas e Respostas`,
        // link: `/ouvidoria/perguntas-e-respostas`,
        link: `${urlADM}/ouvidoria/acompanhamento?token=${tokenLink}`,
      },
      // {
      //   name:``,
      //   link:`/ouvidoria/`,
      // },

      // {
      //   name:`Detalhe`,
      //   link: `/ouvidoria/detalhe`
      // },
    ],
  },
  // Cidadão | e-SIC
  {
    name: `Cidadão | e-SIC `,
    top_list: true,
    img: "Cidadão",
    sub_link: [
      {
        name: `e-SIC - Regulamentação`,
        link: `${urlADM}/esic?token=${tokenLink}`,
      },
      {
        name: `e-SIC - Perguntas e Respostas`,
        link: `http://www.transparenciadministrativa.com.br/esic/perguntaresposta.xhtml?token=${tokenLink}&tipo=Perguntas%20e%20Respostas%20e-Sic`,
      },
      {
        name: `e-SIC`,
        link: `${link}/esic/index.xhtml?token=${tokenLink}`,
      },
      {
        name: `Portaria do responsável pelo e-SIC`,
        link: `${urlADM}/admpublica/divisao/regulamentacao-esic?token=${tokenLink}`,
      },
      {
        name: `Instrumento normativo local que regulamente a LAI`,
        link: `${urlADM}/acesso-a-informacao?token=${tokenLink}`,
      },
      {
        name: `e-SIC - Rol de Informações (12 meses)`,
        link: `/cidadao-e-sic/e-sic-rol-de-informacoes(12meses)`,
      },
      {
        name: `e-SIC - Rol de documentos (Grau Sigilo)`,
        link: `/cidadao-e-sic/e-sic-rol-de-documentos(12meses)`,
      },
    ],
  },
  // Tributos
  // {
  //   name: `Tributos`,
  //   top_list: true,
  //   img: "Contribuinte",
  //   sub_link: [
  //     {
  //       name: `Nota Fiscal Eletrônica`,
  //       // link: `http://tributario.aspec.com.br/portal.ma.raposa/login.xhtml`,
  //       link: `#`,
  //     },
  //     // {
  //     //   name: `2ª via do IPTU`,
  //     //   // link: `http://tributario.aspec.com.br/portal.ma.raposa/UC0085IPTU/T0085L-display-iptu.xhtml`,
  //     //   link: `#`,
  //     // },
  //     // {
  //     //   name: `Certidões`,
  //     //   // link: `http://tributario.aspec.com.br/portal.ma.raposa/UC0084EmitirCertidao/T0084L-emitir-certidao.xhtml`,
  //     //   link: `#`,
  //     // },
  //   ],
  // },
  {
    name: `Portal de Compras`,
    top_list: true,
    img: "Contribuinte",
    link: `https://www.licitasaoroberto.com.br/`,
  },
  {
    name: `LGPD`,
    top_list: true,
    img: "lgpd",
    link: `${urlADM}/lgpd?token=${tokenLink}`,
  },
  // Usuário
  {
    name: `Usuário`,
    top_list: true,
    img: "admin-navbar",
    sub_link: [
      {
        name: `Login - Portal`,
        link: `/admin/login`,
      },
      {
        name: `Login - Diário`,
        link: `${link}/diario/login.xhtml`,
      },
      {
        name: `Login - Licitação`,
        link: `${link}/licitacaotm/login.xhtml`,
      },
      {
        name: `Login - e-SIC`,
        link: `${link}/esic/adm/login.xhtml?token=${tokenLink}`,
      },
      {
        name: `WebMail`,
        link: `https://webmail-seguro.com.br/${dnsPortal}`,
      },
    ],
  },
];
export default linksNavbar;
